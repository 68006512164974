<template>
  <AddSingleFreePin />
</template>

<script>
// @ is an alias to /src
import AddSingleFreePin from "@/components/AddSingleFreePin.vue";

export default {
  name: "AddSingleFreePins",
  components: {
    AddSingleFreePin,
  },
};
</script>
