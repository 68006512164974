<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>变更公告二</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">标记为必填字段</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">{{ alert_message }}</div>
        </div>
        <div class="col-sm-8">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                <i class="fa fa-bullhorn"></i>
              </h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <label class="col-md-4 control-label">
                  公告内容
                  <span class="mandatory_horizontal">*</span>
                </label>
                <div class="col-md-8">
                  <textarea
                    rows="25"
                    class="form-control input-sm"
                    name="announcement_two"
                    id="announcement_two"
                    v-model="announcement_two"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-8 btnRight">
          <input
            type="submit"
            name="submit"
            value="更新"
            class="btn btn-sm btn-submit btn-submit-big"
            @click="formSubmit"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddAnnouncement",
  data() {
    return {
      favicon: "",
      announcement_two: "",
      alert_message: "",
      error_announcement_two: false,
      api_public_path: process.env.VUE_APP_APIADMINPUBLIC,
    };
  },
  created() {
    this.getEditData();
  },
  methods: {
    getEditData() {
      loader.show();
      let rootObject = this;
      axios
        .get("settings")
        .then(function (response) {
          rootObject.announcement_two = response.data.announcement_two;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },
   
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_announcement_two = false;
        }, 5000);
        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("announcement_two", this.announcement_two);
      
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("announcement-save", form, config)
        .then(function (response) {
          console.log(response);
          rootObject.alert_message = "Successfully Saved !";
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });

      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_announcement_two = false;
      }, 5000);
    },

    fromValidation() {
      var has_error = false;
      if (!this.announcement_two) {
        this.error_announcement_two = "此字段是必需的";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
