<template>
  <AddAnnouncementTwo />
</template>

<script>
// @ is an alias to /src
import AddAnnouncementTwo from "@/components/AddAnnouncementTwo.vue";

export default {
  name: "AddAnnouncements",
  components: {
    AddAnnouncementTwo,
  },
};
</script>
