<template>
  <AddAnnouncement />
</template>

<script>
// @ is an alias to /src
import AddAnnouncement from "@/components/AddAnnouncement.vue";

export default {
  name: "AddAnnouncements",
  components: {
    AddAnnouncement,
  },
};
</script>
