<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>添加正式帐号</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">标记为必填字段</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>

        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4">
              <div class="box">
                <div class="box-header">
                  <h3 class="box-title">用户信息</h3>
                </div>
                <div class="box-body form-box-body form-horizontal">
                  <div class="form-group">
                    <div>
                      <div class="col-md-12">
                        <label class="control-label">
                          用户名
                          <span class="mandatory_horizontal">*</span>
                        </label>
                        <input
                          name="username"
                          id="username"
                          class="form-control input-sm"
                          value
                          v-model="username"
                          placeholder="输入用户名"
                          type="text"
                          onfocus="this.removeAttribute('readonly');"
                          @keyup="checkLeadingZero(1)"
                          readonly
                        />
                        <label
                          v-if="error_username"
                          class="error"
                          for="username"
                          >{{ error_username }}</label
                        >
                        <label class="field_suggestion"
                          >* 不要使用零 (0) 作为第一个字母</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">
                        密码
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <input
                        name="password"
                        id="password"
                        class="form-control input-sm"
                        v-model="password"
                        placeholder="订户密码"
                        type="text"
                        onfocus="this.removeAttribute('readonly');"
                        readonly
                      />
                      <label
                        v-if="error_password"
                        class="error"
                        for="password"
                        >{{ error_password }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="box">
                <div class="box-header">
                  <h3 class="box-title">服务限额</h3>
                </div>
                <div class="box-body form-box-body form-horizontal">
                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">当前时间</label>
                      <input
                        name="current_time"
                        id="current_time"
                        class="form-control input-sm"
                        value
                        v-model="current_time"
                        placeholder="当前时间"
                        type="text"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">天数</label>
                      <input
                        name="nod"
                        id="nod"
                        class="form-control input-sm stop-negative-value"
                        v-model="nod"
                        placeholder="天数"
                        type="number"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">到期日期</label>
                      <input
                        name="expire_date"
                        id="expire_date"
                        class="form-control input-sm"
                        value
                        v-model="expire_date"
                        placeholder="到期日期"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">流量配额（MB）</label>
                      <input
                        name="traffic_quota"
                        id="traffic_quota"
                        class="form-control input-sm stop-negative-value"
                        v-model="traffic_quota"
                        placeholder="流量配额（MB）"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div>
                      <div class="col-md-12">
                        <label class="control-label">已使用流量</label>
                        <input
                          name="traffic_already_used"
                          id="traffic_already_used"
                          class="form-control input-sm"
                          value
                          v-model="traffic_already_used"
                          placeholder="已使用流量"
                          type="text"
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div>
                      <div class="col-md-12">
                        <label class="control-label">电子邮件</label>
                        <input
                          name="contact_email"
                          id="contact_email"
                          class="form-control input-sm"
                          value=""
                          v-model="contact_email"
                          placeholder="电子邮件"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="box">
                <div class="box-header">
                  <h3 class="box-title">服务器信息</h3>
                </div>
                <div class="box-body form-box-body form-horizontal">
                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">服务器选择模式</label>
                      <select
                        title
                        name="server_mode_selection"
                        id="server_mode_selection"
                        class="form-control input-sm"
                        v-model="server_mode_selection"
                        @change="changeServerSelectionMode"
                      >
                        <option value>选择</option>
                        <option value="1">单服务器</option>
                        <option value="2">服务器组</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group onlyforsingleserver">
                    <div class="col-md-12">
                      <label class="control-label">选择协议</label>
                      <select
                        title
                        name="protocol_type"
                        id="protocol_type"
                        class="form-control input-sm"
                        v-model="protocol_type"
                        @change="chooseProtocols"
                      >
                        <option value>Select</option>
                        <option value="1">Open VPN</option>
                        <option value="5">WireGuard</option>
                        <option value="4">Shadowsocks</option>
                        <option value="7">V2Ray</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">服务器组</label>
                      <select
                        title
                        name="server_group_id"
                        v-model="server_group_id"
                        v-select="server_group_id"
                        id="server_group_id"
                        class="form-control input-sm select2"
                        onfocus="this.removeAttribute('readonly');"
                        readonly
                        @change="loadServers"
                      >
                        <option value>选择</option>
                        <option
                          v-for="data in bundles"
                          :key="data.id"
                          :value="data.id"
                        >
                          {{ data.name }}
                        </option>
                      </select>
                      <label
                        v-if="error_server_group_id"
                        class="error"
                        for="server_group_id"
                        >{{ error_server_group_id }}</label
                      >
                    </div>
                  </div>

                  <div class="form-group onlyforsingleserver">
                    <div class="col-md-12">
                      <label class="control-label"
                        >服务器ID
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <select
                        title=""
                        name="server_id"
                        v-model="server_id"
                        v-select="server_id"
                        id="server_id"
                        class="form-control input-sm select2"
                        onfocus="this.removeAttribute('readonly');"
                        readonly=""
                        @change="setIPAddress"
                      >
                        <option value="">选择</option>
                        <option
                          v-for="data in servers"
                          :key="data.id"
                          :value="data.id"
                        >
                          {{ data.ip_name }}
                        </option>
                      </select>
                      <label
                        v-if="error_server_id"
                        class="error"
                        for="server_id"
                        >{{ error_server_id }}</label
                      >
                    </div>
                  </div>

                  <div class="form-group displaynone">
                    <div class="col-md-12">
                      <label class="control-label">服务器端口</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="server_port"
                          name="server_port"
                          v-model="server_port"
                          placeholder="server port"
                        />
                        <div
                          class="input-group-addon"
                          @click="randomServerPort"
                        >
                          随机分配
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group displaynone">
                    <div class="col-md-12">
                      <label class="control-label">服务器密码</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="server_password"
                          name="server_password"
                          v-model="server_password"
                          placeholder="server password"
                        />
                        <div
                          class="input-group-addon"
                          @click="randomServerPassword"
                        >
                          随机分配
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <label class="control-label">笔记</label>
                      <input
                        name="note"
                        id="note"
                        class="form-control input-sm"
                        v-model="note"
                        placeholder="笔记"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 btnRight">
          <input
            type="submit"
            name="submit"
            value="生成"
            class="btn btn-sm btn-submit btn-submit-big"
            @click="formSubmit"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddSinglePin",
  data() {
    return {
      userData: {},
      bundles: {},
      servers: {},
      username: "",
      server_group_id: "",
      server_id: "",
      password: "",
      user_type: 2,
      alert_message: "",
      server_mode_selection: "",
      protocol_type: "",
      contact_email: "",

      note: "",
      current_time: "",
      nod: "",
      expire_date: "",
      traffic_quota: "",
      traffic_already_used: 0,
      server_port: "",
      server_password: "",
      ip: "",

      error_username: false,
      error_password: false,
      error_server_group_id: false,
      error_server_id: false,
    };
  },
  created() {
    this.current_time = this.com_currentDateTime();
    this.getEditData();
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);
    this.password = Math.floor(Math.random() * 999999 + 100000);
    var rootObject = this;
    loader.show();
    axios
      .get("ip-bundles")
      .then(function (response) {
        //console.log(response.data.data);
        rootObject.bundles = response.data.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {
    setIPAddress() {
      this.ip = "";
      if (this.server_id != "") {
        for (var prop in this.servers) {
          if (this.servers[prop]["id"] == this.server_id) {
            this.ip = this.servers[prop]["ip"];
          }
        }
      }
    },

    randomServerPort() {
      this.server_port = Math.floor(Math.random() * 99999 + 10000);
    },

    randomServerPassword() {
      var chars = "abcdefghijklmnopqrstuvwxyz";
      var passwordLength = 12;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      this.server_password = password;
    },

    getEditData() {
      loader.show();
      let rootObject = this;
      var pin_id = this.$route.params.id;
      axios
        .get("pins/" + pin_id)
        .then(function (response) {
          //console.log(response.data)
          rootObject.username = response.data.username;
          rootObject.password = response.data.value;
          rootObject.nod = response.data.no_of_days;
          rootObject.user_type = response.data.user_type;
          rootObject.expire_date = response.data.validity_date;
          rootObject.contact_email = response.data.contact_email;
          rootObject.status = false;
          rootObject.id = response.data.id;
          if (response.data.status == 1) {
            rootObject.status = true;
          }
          if (response.data.additional_data) {
            let AdditionlData = JSON.parse(response.data.additional_data);
            rootObject.traffic_quota = AdditionlData.traffic_quota;
            rootObject.note = AdditionlData.note;
            rootObject.traffic_already_used =
              AdditionlData.traffic_already_used;
            rootObject.server_port = AdditionlData.server_port;
            rootObject.server_password = AdditionlData.server_password;
            rootObject.server_group_id = AdditionlData.server_group_id;
            rootObject.ip = AdditionlData.ip;
            rootObject.server_mode_selection =
              AdditionlData.server_mode_selection;
            rootObject.protocol_type = AdditionlData.protocol_type;
            rootObject.loadServers(AdditionlData.server_id);
          }
          rootObject.changeServerSelectionMode();
          rootObject.chooseProtocols();
          // rootObject.general_ipbundle_id = response.data.general_user_ip_bundle;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_username = false;
          rootObject.error_password = false;
          rootObject.error_server_group_id = false;
        }, 5000);

        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("username", this.username);
      form.append("server_group_id", this.server_group_id);
      form.append("server_id", this.server_id);
      form.append("value", this.password);
      form.append("password", this.password);
      form.append("user_type", this.user_type);

      form.append("note", this.note);
      form.append("current_time", this.current_time);
      form.append("nod", this.nod);
      // form.append("expire_date", this.expire_date);
      form.append("traffic_quota", this.traffic_quota);
      form.append("traffic_already_used", this.traffic_already_used);
      form.append("server_port", this.server_port);
      form.append("server_password", this.server_password);
      form.append("ip", this.ip);
      form.append("server_mode_selection", this.server_mode_selection);
      form.append("protocol_type", this.protocol_type);
      form.append("contact_email", this.contact_email);

      form.append("_method", "put");
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("pins/" + this.id, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = "订阅者已成功更新";
          rootObject.username = "";
          rootObject.$router.push("/officialaccountlist");
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });
      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_username = false;
        rootObject.error_password = false;
        rootObject.error_server_group_id = false;
      }, 5000);
    },

    loadServers($selected = false) {
      var rootObject = this;
      if (this.server_group_id) {
        loader.show();

        axios
          .get("ip-bundle-details/" + this.server_group_id)
          .then(function (response) {
            //console.log(response.data.ipBundleDetails);
            rootObject.servers = response.data.ipBundleDetails;
            rootObject.server_id = "";
            if ($selected) {
              rootObject.server_id = $selected;
            }
            loader.hide();
          })
          .catch(function (error) {
            let alertmessage = "";
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
            console.log(alertmessage);
            loader.hide();
            rootObject.com_errorHandel(error);
          });
      } else {
        rootObject.servers = {};
        rootObject.server_id = "";
      }
    },

    checkLeadingZero(field_no) {
      var val = "";
      if (field_no == 1) {
        val = this.username;
      }

      if (field_no == 2) {
        val = this.password;
      }
      var firstDigits = ("" + val).split("")[0];
      if (firstDigits == "0") {
        $.alert("Please, Do not use Zero (0) as a first letter for this field");
        if (field_no == 1) {
          this.username = "";
        }
        if (field_no == 2) {
          this.password = "";
        }
      }
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.username) {
        this.error_username = "此字段是必需的";
        has_error = true;
      }

      if (!this.password) {
        this.error_password = "此字段是必需的";
        has_error = true;
      }

      if (!this.server_group_id) {
        this.error_server_group_id = "此字段是必需的";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    changeServerSelectionMode() {
      if (this.server_mode_selection == 2) {
        $(".onlyforsingleserver").hide();
      } else {
        $(".onlyforsingleserver").show();
      }
    },

    chooseProtocols() {
      $(".onlyshadowsocks").hide();
      if (this.protocol_type == 4) {
        $(".onlyshadowsocks").show();
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });

    $(".stop-specialchar").keypress(function (e) {
      var keyCode = e.keyCode || e.which;
      var regexp = new RegExp("^[a-zA-Z0-9]+$");
      var result = regexp.test(String.fromCharCode(keyCode));
      if (!result) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-group .input-group-addon {
  background-color: #d1d1d182;
  cursor: pointer;
}
.displaynone {
  display: none;
}
</style>
