<template>
  <select
    title=""
    id="short_filter"
    name="short_filter"
    class="form-control input-sm select2"
  >
    <option value="">过滤持续时间</option>
    <option value="1">今天</option>
    <option value="2">昨天</option>
    <option value="3">本星期</option>
    <option value="4">上星期</option>
    <option value="5">过去 7 天</option>
    <option value="6">这个月</option>
    <option value="8">过去 15 天</option>
    <option value="7">过去 30 天</option>
  </select>
</template>
<script>
export default {
  name: "filterDropdownOptions",
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
