<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-8">
          <h1>搜索测试帐户</h1>
        </div>
        <div class="col-xs-4">
          <router-link
            :to="{ name: 'AddTestAccounts' }"
            class="btn btn-sm btn-add"
          >
            <i class="fa fa-plus-square"></i> 添加测试帐户
          </router-link>
          <a class="btn btn-sm btn-add refresh" @click="refreshNow">
            <i class="fa fa-refresh"></i> 刷新
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="搜索用户名"
              type="text"
              name="login"
              v-model="login"
              id="login"
              class="form-control input-sm"
              value=""
            />
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="limit"
              id="limit"
              v-model="display_limit"
              v-select="display_limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-3 form-group pull-right btnRight">
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> 搜索
            </button>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <form>
              <div class="box-header">
                <h3 class="box-title">
                  总计 : {{ totalData }} 展示 {{ dispplayFrom }} -
                  {{ dispplayTo }}
                  <div class="form-inline pull-right" id="activation1">
                    <label style="color: green; font-weight: bold">选择 </label>
                    <div class="form-group">
                      <select
                        title=""
                        id="activation_option1"
                        name="activation"
                        class="form-control input-sm"
                        v-model="operation_type"
                      >
                        <option value="1">启用</option>
                        <option value="2">停用</option>
                      </select>
                    </div>
                    <input
                      class="btn btn-sm btn-submit"
                      type="button"
                      name="button"
                      value="提交"
                      @click="mulOperation()"
                    />
                  </div>
                </h3>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table class="table table-0024 table-bordered table-striped">
                    <thead>
                      <tr>
                        <th class="tdCenter tdSN">序列号</th>
                        <th class="tdCenter">在线状态</th>
                        <th class="tdCenter">服务器地址</th>
                        <th class="tdCenter">服务器端口</th>
                        <th class="tdCenter">天数</th>
                        <th class="tdCenter">使用的流量</th>
                        <th class="tdCenter">流量配额</th>
                        <th class="tdCenter">到期时间</th>
                        <th class="tdCenter">用户评论</th>
                        <th class="tdCenter">状态</th>
                        <th class="tdCenter">
                          <span class="thSelectAll">全部</span>
                          <input
                            type="checkbox"
                            id="check_all"
                            @click="checkAll"
                          />
                        </th>
                        <th class="tdCenter" style="width: 60px">活动</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in pinListArr" :key="data.id">
                        <td class="tdCenter tdSN">
                          {{ parseInt(sl) + index }}
                        </td>
                        <td class="tdCenter">
                          <span class="online-status">
                            <i class="fa fa-circle text-danger"></i> &nbsp;
                            离线</span
                          >
                        </td>
                        <td class="tdCenter info">
                          {{ com_jsonParseData(data.additional_data, "ip") }}
                        </td>
                        <td class="tdCenter info">
                          {{
                            com_jsonParseData(
                              data.additional_data,
                              "server_port"
                            )
                          }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.no_of_days }}
                        </td>
                        <td class="tdCenter info">
                          {{
                            com_jsonParseData(
                              data.additional_data,
                              "traffic_already_used"
                            )
                          }}
                        </td>
                        <td class="tdCenter info">
                          {{
                            com_jsonParseData(
                              data.additional_data,
                              "traffic_quota"
                            )
                          }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.validity_date }}
                        </td>
                        <td class="tdCenter">
                          {{ com_jsonParseData(data.additional_data, "note") }}
                        </td>
                        <td class="tdCenter" v-if="data.status == 1">
                          <span class="label label-success">积极的</span>
                        </td>
                        <td class="tdCenter" v-else-if="data.status == 2">
                          <span class="label label-danger">不活跃</span>
                        </td>
                        <td class="tdCenter" v-else>
                          <span class="label label-warning">到期</span>
                        </td>
                        <td class="tdCenter">
                          <input type="hidden" :value="data.status" />
                          <input
                            class="user_id"
                            name="user_id[]"
                            type="checkbox"
                            :value="data.id"
                          />
                        </td>
                        <td class="tdCenter">
                          <a
                            v-if="
                              data.status == 1 &&
                              data.udid &&
                              userData.reseller.pin_reset == 1 &&
                              userData.reseller4_id != 98 &&
                              userData.reseller4_id != 304
                            "
                            title="Reset UDID"
                            class="btn btn-sm text-warning"
                            @click="resetPin(data.id)"
                            style="padding: 2px 5px"
                          >
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="pinListArr.length == 0">
                        <td
                          colspan="15"
                          class="tdCenter"
                          style="padding: 30px 0"
                        >
                          <b>没有找到记录</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style="display: none">
                  <div id="viewDeviceList">
                    <div class="box" style="padding: 20px">
                      <div class="box-header">
                        <h3 class="box-title"><b></b> 设备信息</h3>
                      </div>
                      <div class="box-body">
                        <table
                          class="table table-0024 table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th class="tdCenter tdSN">序列号</th>
                              <th class="tdCenter">设备类型</th>
                              <th class="tdCenter">品牌</th>
                              <th class="tdCenter">模型</th>
                              <th class="tdCenter">操作系统名称</th>
                              <th class="tdCenter">操作系统版本</th>
                              <th class="tdCenter">建筑学</th>
                              <th class="tdCenter">应用程序版本</th>
                              <th class="tdCenter">已扎根</th>
                              <th class="tdCenter">UDID</th>
                              <th class="tdCenter">创建于</th>
                            </tr>
                          </thead>
                          <tbody v-if="deviceArr.length > 0">
                            <tr
                              :class="val.actual_price == '' ? 'warning' : ''"
                              v-for="(val, index) in deviceArr"
                            >
                              <td class="tdCenter tdSN">{{ ++index }}</td>
                              <td class="tdCenter">
                                <span v-if="val.device_type == 1">Android</span>
                                <span v-if="val.device_type == 2">IOS</span>
                              </td>
                              <td class="tdCenter">
                                {{ val.brand }}
                              </td>
                              <td class="tdCenter">
                                {{ val.model }}
                              </td>
                              <td class="tdCenter">
                                {{ val.os_name }}
                              </td>
                              <td class="tdCenter">
                                {{ val.os_version }}
                              </td>
                              <td class="tdCenter">
                                {{ val.os_platform }}
                              </td>
                              <td class="tdCenter">
                                {{ val.vpn_app_version }}
                              </td>
                              <td class="tdCenter">
                                <span v-if="val.is_rooted_phone == 1">Yes</span>
                                <span v-else>No</span>
                              </td>
                              <td class="tdCenter">
                                {{ val.udid }}
                              </td>
                              <td class="tdCenter">
                                {{ val.created_at }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                colspan="11"
                                class="tdCenter"
                                style="padding: 30px 0px"
                              >
                                没有找到记录
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pagination" v-if="last_page > 1">
                  <a
                    v-if="prev_page_url"
                    href="#"
                    @click="dopagination(prevPage)"
                    class="next"
                  >
                    « Previous
                  </a>
                  <span v-else class="disabled">« Previous</span>
                  <span v-if="last_page > max_display_pagination">
                    <span v-for="index in last_page">
                      <span v-if="index == 1 || index == 2">
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                        <span v-if="index == 2 && firstDotted">..</span>
                      </span>
                      <span v-if="randomPageNumber.includes(index)">
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                      </span>
                      <span v-if="index == last_page || index == last_page - 1">
                        <span v-if="index == last_page - 1 && lastDotted">
                          ..
                        </span>
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span v-for="index in last_page">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                  </span>
                  <a
                    v-if="next_page_url"
                    href="#"
                    @click="dopagination(nextPage)"
                    class="next"
                  >
                    Next »
                  </a>
                  <span v-else class="disabled">Next »</span>
                </div>
              </div>
              <div class="box-footer"></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "FreePinList",
  data() {
    return {
      userData: {},
      pinListArr: [],
      rate_plan_list: {},
      login: "",
      udid: "",
      plan_details_id: "",
      lots_id: 0,
      deviceArr: {},

      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      status: 1,
      user_type: 3,
      sl: 1,
      operation_type: 1,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);
    var rootObject = this;
    loader.show();
    this.dopagination(0);
    axios
      .get("rate-plan-details")
      .then(function (response) {
        //console.log(response.data);
        rootObject.rate_plan_list = response.data.ratePlanDtlsData;
      })
      .catch(function (error) {
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });

    loader.hide();
    //this.loadPinList();
  },
  methods: {
    loadPinList() {
      loader.show();
      var rootObject = this;
      axios
        .get("pins")
        .then(function (response) {
          rootObject.populatePinListData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;

      let querystr =
        "login=" +
        this.login +
        "&status=" +
        this.status +
        "&user_type=" +
        this.user_type +
        "&paginate=" +
        this.display_limit +
        "&batch_id=" +
        this.lots_id +
        "&udid=" +
        this.udid +
        "&plan_details_id=" +
        this.plan_details_id;

      if (page_no > 0) {
        querystr += "&page=" + page_no;
      } else {
        querystr += "&page=1";
      }
      //console.log(querystr);

      axios
        .get("pins?" + querystr)
        .then(function (response) {
          rootObject.populatePinListData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populatePinListData(response) {
      this.sl = parseInt(response.data.from);

      var current_date_time = this.com_currentDateTime();
      var userData = this.userData;
      for (var prop in response.data.data) {
        var allowresetpin = false;
        var showPeriod = this.com_modifyDateTime(
          3,
          response.data.data[prop]["after_activate_first_login_at"]
        );
        if (showPeriod >= current_date_time) {
          if (
            response.data.data[prop]["reseller_level"] == userData.user_level
          ) {
            allowresetpin = true;
          }
        }
        response.data.data[prop]["allowresetpin"] = allowresetpin;
      }
      this.pinListArr = response.data.data;
      //console.log(this.pinListArr);

      this.totalData = response.data.total;
      this.dispplayFrom = response.data.from;
      this.dispplayTo = response.data.to;
      this.display_limit = response.data.per_page;
      this.paginationGenerate(response);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.data.prev_page_url;
      this.next_page_url = response.data.next_page_url;
      this.last_page = response.data.last_page;
      this.current_page = response.data.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    resetPin(pin_id) {
      //console.log(pin_id);
      var rootObject = this;
      let msg = "您确定要重置此udid吗?";

      $.confirm({
        title: "确认!",
        content: msg,
        buttons: {
          yes: function () {
            loader.show();
            axios
              .get("reset-udid/" + pin_id)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "UDID重置成功";
                  loader.hide();
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            //return;
          },
        },
      });
    },

    checkAll() {
      if ($("#check_all").prop("checked") == true) {
        $(".user_id").prop("checked", "checked");
      } else {
        $(".user_id").prop("checked", false);
      }
    },

    mulOperation() {
      var rootObject = this;

      var checked = document.querySelectorAll('input[name="user_id[]"]:checked')
        .length;
      if (checked == 0) {
        $.alert("请至少选择一个帐户");
        return;
      }

      /*status count message start*/
      var activePins = [];
      var inActivePins = [];
      var active_count = 0;
      var inactive_count = 0;
      $('input[name="user_id[]"]:checked').each(function () {
        const status = $(this).prev().val();
        if (status == 1) {
          active_count += 1;
          activePins.push($(this).val());
        } else {
          inactive_count += 1;
          inActivePins.push($(this).val());
        }
      });
      var activate_message =
        active_count > 0 ? `${active_count} Account is active.` : "";
      var in_activate_message =
        inactive_count > 0 ? `${inactive_count} Account is Inactive.` : "";
      /*status count message end*/

      var activation_option = this.operation_type;

      if (activation_option == 1) {
        if (inactive_count < 1) {
          $.alert("所有帐户均已激活.");
          return;
        } else {
          var msg = ` ${activate_message} ${in_activate_message} 您确定要激活吗 ${inactive_count} 帐户?`;
        }

        var users = inActivePins;
      }

      if (activation_option == 2) {
        if (active_count < 1) {
          $.alert("所有帐户均已处于非活动状态.");
          return;
        } else {
          var msg = `${in_activate_message} ${activate_message} 您确定要停用吗 ${active_count} 帐户?`;
        }

        var users = activePins;
      }

      //console.log(users);

      $.confirm({
        title: "确认!",
        content: msg,
        buttons: {
          yes: function () {
            loader.show();
            const form = new FormData();
            form.append("operation_type", activation_option);
            form.append("users", JSON.stringify(users));
            var config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            axios
              .post("bulk-pin-operation", form, config)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "Account " + response.data;
                  rootObject.checkAll();
                  loader.hide();
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            return;
          },
        },
      });

      //console.log(this.operation_type);
    },

    contentEdit(id) {
      var valId = "#passWord-" + id;
      $(valId).prop("contenteditable", true);
    },

    updatePassword(id) {
      var rootObject = this;
      var valId = "#passWord-" + id;
      var username = $(valId).attr("username");
      var originalVal = $(valId).attr("original-value");

      $(valId).css("cursor", "default");
      $(valId).prop("contenteditable", false);

      var id = id;
      var newPassWord = $(valId).html();
      var newPassWord = newPassWord.replace("<br>", "");
      var newPassWord = newPassWord.replace("&nbsp;", "");
      var newPassWord = newPassWord.replace(/ /g, "");

      if (newPassWord == originalVal) return;

      if (newPassWord == "") {
        $.alert("password can not be empty");
        $(valId).text(originalVal);
        return;
      }
      if (newPassWord.length < 4) {
        $.alert("Min password length is 4");
        $(valId).text(originalVal);
        return;
      }

      var firstDigits = ("" + newPassWord).split("")[0];
      if (isNaN(newPassWord)) {
        $.alert("Please, input only number value as password");
        $(valId).text(originalVal);
        return;
      }
      if (firstDigits == "0") {
        $.alert("Zero (0) is not allowed as first letter of password");
        $(valId).text(originalVal);
        return;
      }

      $(valId).text(newPassWord);

      loader.show();
      const form = new FormData();
      form.append("id", id);
      form.append("value", newPassWord);
      form.append("username", username);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("reset-password", form, config)
        .then(function (response) {
          //console.log(response);
          if (response.status == 200) {
            rootObject.alert_message = response.data;
            loader.hide();
            setTimeout(function () {
              rootObject.alert_message = "";
            }, 3000);
          }
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          $.alert(alertmessage);
        });
    },

    loadDeviceList(id) {
      loader.show();
      var rootObject = this;
      axios
        .get("device-details?id=" + id)
        .then(function (response) {
          //console.log(response.data.deviceDtls);
          rootObject.deviceArr = response.data.deviceDtls;
          setTimeout(function () {
            $(".colorbox").colorbox({
              opacity: 0.8,
              height: "200px",
              width: "80%",
              inline: true,
              open: true,
              href: function () {
                return "#viewDeviceList";
              },
            });
            loader.hide();
          }, 500);
          //rootObject.loadLinkPopup(id, response.data.ratePlans);
        })
        .catch(function (error) {
          //console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    refreshNow() {
      this.login = "";
      this.udid = "";
      this.plan_details_id = "";
      this.lots_id = 0;
      this.display_limit = 50;
      this.status = 1;
      this.last_page = 1;
      this.pinListArr = [];
      this.dopagination(0);
    },

    showPinPass(val) {
      let rootObject = this;
      var id = val;
      $("#passwordShowDiv_" + id).html(
        '<img src="/assets/img/loading_h.gif" alt="Loading"/>'
      );
      const form = new FormData();
      form.append("id", id);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("show-pin-pass", form, config)
        .then(function (response) {
          console.log(response);
          if (response.status == 200) {
            $("#passwordShowDiv_" + id).html(response.data);
          }
        })
        .catch(function (error) {
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          $.alert(alertmessage);
        });
    },
  },

  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
