<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>面板设置信息</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">标记为必填字段</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <form role="form">
          <div class="col-sm-12">
            <div class="alert alert-info" v-if="alert_message">{{ alert_message }}</div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">简介信息</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    品牌
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="com_name"
                      id="com_name"
                      class="form-control input-sm"
                      placeholder="机构名称"
                      type="text"
                      maxlength="20"
                      v-model="com_name"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label class="field_suggestion">该值将用作面板名称.</label>
                    <label v-if="error_com_name" class="error" for="name">
                      {{
                      error_com_name
                      }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    电话号码
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="com_phone"
                      id="com_phone"
                      class="form-control input-sm"
                      placeholder="电话号码"
                      type="text"
                      v-model="com_phone"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label class="field_suggestion">该值将用于经销商和 Cdr 登录页面.</label>
                    <label v-if="error_com_phone" class="error" for="name">
                      {{
                      error_com_phone
                      }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label">地址</label>
                  <div class="col-md-8">
                    <textarea
                      class="form-control input-sm"
                      name="com_address"
                      placeholder="地址"
                      id="com_address"
                      v-model="com_address"
                      onfocus="this.removeAttribute('readonly');"
                    ></textarea>
                    <label class="field_suggestion">该值将用于经销商和 Cdr 登录页面.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">面板用户界面信息</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label for="com_name_color" class="col-md-4 control-label">面板名称 颜色</label>
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="com_name_color"
                      class="form-control valid"
                      id="com_name_color"
                      v-model="com_name_color"
                      aria-invalid="false"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="login_header_color" class="col-md-4 control-label">登录标题颜色</label>
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="login_header_color"
                      class="form-control valid"
                      id="login_header_color"
                      aria-invalid="false"
                      v-model="login_header_color"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="switch_header_color" class="col-md-4 control-label">面板标题颜色</label>
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="switch_header_color"
                      class="form-control valid"
                      id="switch_header_color"
                      v-model="switch_header_color"
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">徽标和图标</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-3 control-label">以前的标志</label>
                  <div class="col-md-3">
                    <img
                      width="100"
                      class="img-responsive img-bordered img-thumbnail"
                      alt="Logo"
                      :src="logo"
                    />
                  </div>
                  <label class="col-md-3 control-label">网站图标</label>
                  <div class="col-md-3">
                    <img
                      width="50"
                      class="img-responsive img-bordered img-thumbnail"
                      alt="Logo"
                      :src="favicon"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-2 control-label">新标志</label>
                  <div class="col-md-4">
                    <input
                      class="form-control input-sm"
                      name="logo_images"
                      type="file"
                      ref="file"
                      @change="onFileSelected"
                    />
                    <label class="field_suggestion">最低限度:100X100 px, 最大限度:512X512 px.</label>
                  </div>
                  <label class="col-md-2 control-label">新 ICO</label>
                  <div class="col-md-4">
                    <input
                      class="form-control input-sm"
                      name="favicon"
                      type="file"
                      ref="file"
                      @change="onFileSelected2"
                    />
                    <label class="field_suggestion">类型: ico, 最大限度: 80kb.</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="box">
              <div class="box-header">
                <h3 class="box-title">链接</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-md-4 control-label">买</label>
                      <div class="col-md-8">
                        <input
                          name="purchase_link"
                          id="purchase_link"
                          class="form-control input-sm"
                          placeholder="买"
                          type="text"
                          v-model="purchase_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">更改计划</label>
                      <div class="col-md-8">
                        <input
                          name="change_plan_link"
                          id="change_plan_link"
                          class="form-control input-sm"
                          placeholder="更改计划"
                          type="text"
                          v-model="change_plan_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">关于我们</label>
                      <div class="col-md-8">
                        <input
                          name="about_us_link"
                          id="about_us_link"
                          class="form-control input-sm"
                          placeholder="关于我们"
                          type="text"
                          v-model="about_us_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">获得支持</label>
                      <div class="col-md-8">
                        <input
                          name="get_support_link"
                          id="get_support_link"
                          class="form-control input-sm"
                          placeholder="获得支持"
                          type="text"
                          v-model="get_support_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-md-4 control-label">电子邮件</label>
                      <div class="col-md-8">
                        <input
                          name="email_link"
                          id="email_link"
                          class="form-control input-sm"
                          placeholder="电子邮件"
                          type="text"
                          v-model="email_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">WhatsApp</label>
                      <div class="col-md-8">
                        <input
                          name="whatsapp_link"
                          id="whatsapp_link"
                          class="form-control input-sm"
                          placeholder="WhatsApp Link"
                          type="text"
                          v-model="whatsapp_link"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">域名链接 1</label>
                      <div class="col-md-8">
                        <input
                          name="domain_link"
                          id="domain_link"
                          class="form-control input-sm"
                          placeholder="网站链接"
                          type="text"
                          v-model="domain_link_1"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">域名链接 2</label>
                      <div class="col-md-8">
                        <input
                          name="domain_link_2"
                          id="domain_link_2"
                          class="form-control input-sm"
                          placeholder="网站链接"
                          type="text"
                          v-model="domain_link_2"
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">E Pay 环境</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    帐号
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="epay_account_id"
                      id="epay_account_id"
                      class="form-control input-sm"
                      placeholder="帐号"
                      type="text"
                      maxlength="20"
                      v-model="epay_account_id"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label v-if="error_epay_account_id" class="error" for="epay_account_id">
                      {{
                      error_epay_account_id
                      }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    帐户密钥
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="epay_account_key"
                      id="epay_account_key"
                      class="form-control input-sm"
                      placeholder="帐户密钥"
                      type="text"
                      v-model="epay_account_key"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label v-if="error_epay_account_key" class="error" for="epay_account_key">
                      {{
                      error_epay_account_key
                      }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    网站链接
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="epay_account_url"
                      id="epay_account_url"
                      class="form-control input-sm"
                      placeholder="网站链接"
                      type="text"
                      v-model="epay_account_url"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label v-if="error_epay_account_url" class="error" for="epay_account_url">
                      {{
                      error_epay_account_url
                      }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">使能够</label>
                  <div class="col-md-8">
                    <input
                      name="active_payment"
                      id="active_payment"
                      type="radio"
                      v-model="active_payment"
                      value="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Alipay 环境</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    客户编号
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="alipay_client_id"
                      id="alipay_client_id"
                      class="form-control input-sm"
                      placeholder="客户编号"
                      type="text"
                      v-model="alipay_client_id"
                      onfocus="this.removeAttribute('readonly');"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    网址
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="alipay_client_url"
                      id="alipay_client_url"
                      class="form-control input-sm"
                      placeholder="网址"
                      type="text"
                      v-model="alipay_client_url"
                      onfocus="this.removeAttribute('readonly');"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">使能够</label>
                  <div class="col-md-8">
                    <input
                      name="active_payment"
                      id="active_payment"
                      type="radio"
                      v-model="active_payment"
                      value="2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="提交"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddPanelSetting",
  data() {
    return {
      active_payment: 1,
      epay_account_id: "",
      epay_account_key: "",
      epay_account_url: "",
      alipay_client_id: "",
      alipay_client_url: "",
      com_name: "",
      com_phone: "",
      com_address: "",
      com_name_color: "",
      login_header_color: "",
      login_bg_color: "",
      switch_header_color: "",
      logo: "",
      favicon: "",
      logo_images: "",
      favicon_images: "",
      id: "",
      logo_image_file: null,
      favicon_image_file: null,
      api_public_path: process.env.VUE_APP_APIADMINPUBLIC,
      purchase_link: "",
      change_plan_link: "",
      about_us_link: "",
      get_support_link: "",
      email_link: "",
      whatsapp_link: "",
      domain_link_1: "",
      domain_link_2: "",

      error_com_name: false,
      error_epay_account_id: false,
      error_epay_account_key: false,
      error_epay_account_url: false,
      error_com_phone: false,

      alert_message: "",
      error_name: false,
    };
  },
  created() {
    var access = this.com_verifyAllowOrNotSetting();
    if (access === false) {
      this.$router.push("dashboard");
    }
    var panelData = this.$store.getters.getPanelData;
    this.com_name = panelData.com_name;
    this.com_phone = panelData.com_phone;
    this.com_address = panelData.com_address;
    this.com_name_color = panelData.com_name_color;
    this.login_header_color = panelData.login_header_color;
    this.login_bg_color = panelData.login_bg_color;
    this.switch_header_color = panelData.switch_header_color;
    this.epay_account_id = panelData.epay_account_id;
    this.epay_account_key = panelData.epay_account_key;
    this.epay_account_url = panelData.epay_account_url;
    this.alipay_client_id = panelData.alipay_client_id;
    this.alipay_client_url = panelData.alipay_client_url;
    this.active_payment = panelData.active_payment;

    this.purchase_link = panelData.purchase_link;
    this.change_plan_link = panelData.change_plan_link;
    this.about_us_link = panelData.about_us_link;
    this.get_support_link = panelData.get_support_link;
    this.email_link = panelData.email_link;
    this.whatsapp_link = panelData.whatsapp_link;
    this.domain_link_1 = panelData.domain_link_1;
    this.domain_link_2 = panelData.domain_link_2;
    this.id = panelData.id;

    console.log("this.active_payment : ", this.active_payment);

    this.logo = "/assets/img/nologo.png";
    this.favicon = "/assets/img/nologo.png";

    var logoImgURL =
      this.api_public_path + "uploads/images/" + panelData.logo_images;
    this.checkIfImageExists(logoImgURL, (exists) => {
      if (exists) {
        this.logo = logoImgURL;
      }
    });

    var faviconImgURL =
      this.api_public_path + "uploads/images/" + panelData.favicon;
    this.checkIfImageExists(faviconImgURL, (exists) => {
      if (exists) {
        this.favicon = faviconImgURL;
      }
    });
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
        img.onerror = () => {
          callback(false);
        };
      }
    },

    formSubmit(e) {
      var panel = this.$route.params.panel;
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("purchase_link", this.purchase_link);
      form.append("change_plan_link", this.change_plan_link);
      form.append("about_us_link", this.about_us_link);
      form.append("get_support_link", this.get_support_link);
      form.append("email_link", this.email_link);
      form.append("whatsapp_link", this.whatsapp_link);
      form.append("domain_link_1", this.domain_link_1);
      form.append("domain_link_2", this.domain_link_2);

      form.append("active_payment", this.active_payment);
      form.append("epay_account_id", this.epay_account_id);
      form.append("epay_account_key", this.epay_account_key);
      form.append("epay_account_url", this.epay_account_url);
      form.append("alipay_client_id", this.alipay_client_id);
      form.append("alipay_client_url", this.alipay_client_url);
      form.append("com_name", this.com_name);
      form.append("com_phone", this.com_phone);
      form.append("com_address", this.com_address);
      form.append("com_name_color", this.com_name_color);
      form.append("login_header_color", this.login_header_color);
      form.append("login_bg_color", this.login_bg_color);
      form.append("switch_header_color", this.switch_header_color);
      form.append("logo_image_file", this.logo_image_file);
      form.append("favicon_image_file", this.favicon_image_file);
      form.append("_method", "put");

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("update-panel-setting/" + this.id, form, config)
        .then(function (response) {
          //console.log(response.data.result.settings);
          let panelparam = {
            panel: panel,
            panel_settings: response.data.result.settings,
          };
          rootObject.logo =
            rootObject.api_public_path +
            "uploads/images/" +
            response.data.result.settings.logo_images;
          rootObject.favicon =
            rootObject.api_public_path +
            "uploads/images/" +
            response.data.result.settings.favicon;
          rootObject.com_setPanelData(panelparam);
          rootObject.alert_message = "设置成功更新";
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      this.error_com_name = false;
      this.error_com_phone = false;
      this.error_epay_account_id = false;
      this.error_epay_account_key = false;
      this.error_epay_account_url = false;

      if (!this.epay_account_id) {
        this.error_epay_account_id = "此字段是必需的";
        has_error = true;
      }

      if (!this.epay_account_key) {
        this.error_epay_account_key = "此字段是必需的";
        has_error = true;
      }

      if (!this.epay_account_url) {
        this.error_epay_account_url = "此字段是必需的";
        has_error = true;
      }

      if (!this.com_name) {
        this.error_com_name = "此字段是必需的";
        has_error = true;
      }

      if (!this.com_phone) {
        this.error_com_phone = "此字段是必需的";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.logo_image_file = event.target.files[0];
    },

    onFileSelected2(event) {
      //console.log(event.target.files[0]);
      this.favicon_image_file = event.target.files[0];
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
